import * as React from 'react';
import cn from 'classnames';
import Grid from 'styleguide/components/Grid/Grid';
import DatePicker from 'styleguide/components/DatePicker/DatePicker';
import Loader from 'styleguide/components/Loader/Loader';
import { defaultDeliveryTime } from 'api/simplyShip';
import RushCriticalModal from '../RushCriticalModal/RushCriticalModal';
import AppContext from 'contexts/AppContext/AppContext';
import OrderContext from 'contexts/OrderContext/OrderContext';
import SimplyShipContext from 'contexts/SimplyShipContext/SimplyShipContext';
import { format } from 'date-fns';
import { normalizeAvailableDates, AvailableDatesNormalized } from 'app/utils/simplyShip';
import { Combobox } from 'styleguide/components/forms';

interface Props {
  checkoutPageMode?: boolean;
}

const DeliveryDetail = ({ checkoutPageMode }: Props) => {
  const [rushCriticalModalShown, setRushCriticalModalShown] = React.useState<boolean>(false);
  const [previouslyNormalizedAvailableDates, setPreviouslyNormalizedAvailableDates] =
    React.useState<AvailableDatesNormalized | null>(null);
  const { order } = React.useContext(OrderContext);
  const simplyShipContext = React.useContext(SimplyShipContext);
  const appContext = React.useContext(AppContext);

  const {
    newArrival: { readyAtDate, deliveryDate, selectedTime },
    availableDates,
    availableTimes,
    onReadyAtChange,
    onDateChange,
  } = simplyShipContext;

  const isRushCriticalDate = (date: Date) => {
    if (!availableDates.rushCriticalDates) {
      return false;
    }

    return !!availableDates.rushCriticalDates.find(strDate => strDate === format(date, 'M/d/yyyy'));
  };

  const availableDatesNormalized = React.useMemo(() => {
    if (!availableDates) {
      return previouslyNormalizedAvailableDates;
    }
    const normalized = normalizeAvailableDates(availableDates);
    setPreviouslyNormalizedAvailableDates(normalized);
    return normalized;
  }, [availableDates]);

  return (
    <Grid.Container>
      {rushCriticalModalShown && <RushCriticalModal onClose={() => setRushCriticalModalShown(false)} />}
      {appContext.store.admin && (
        <Grid.Row>
          <Grid.Col>
            <div>{`Production days: ${order.productionDays || '-'}, 
              Ship days: ${order.shipment?.shippingDays || '-'}, 
              Shipping method: ${order.shipment?.shippingMethod || '-'}`}</div>
          </Grid.Col>
        </Grid.Row>
      )}
      {appContext.store.admin && !!readyAtDate && (
        <Grid.Row
          className={cn(
            'border-t border-blue-200 pl-7 pr-7 pb-8 m-0 md:mb-6 md:pb-0',
            !!checkoutPageMode && 'flex border-0 p-0 -mx-3',
            !!appContext.store.admin && 'min-h-0 justify-start',
          )}
        >
          <Grid.Col className="py-5" sm={12} md={6} lg={8}>
            <label>Ready At Date</label>
            <DatePicker
              twoMonths
              selectedDate={readyAtDate}
              disabledDays={[
                ...appContext.store.holidays.map(date => new Date(date.replace(/-/g, '/'))),
                {
                  dayOfWeek: [0, 6],
                },
              ]}
              onDateSelect={date => onReadyAtChange(date)}
              customText={readyAtDate ? format(readyAtDate, 'MMM do') : null}
              dates={{
                freeShipping: [],
                critical: [],
                standard: [],
              }}
            />
          </Grid.Col>
        </Grid.Row>
      )}
      {availableDatesNormalized && deliveryDate ? (
        <Grid.Row
          className={cn(
            'border border-blue-200 pl-7 pr-7 pb-8 !m-0 md:mb-6 md:pb-0',
            !!checkoutPageMode && 'flex border-0 p-0 -mx-3',
          )}
        >
          <Grid.Col className="py-5" sm={12} md={6} lg={6}>
            <DatePicker
              twoMonths
              selectedDate={deliveryDate}
              dataCy="deliveryDetailDatePicker"
              disabledDays={[
                ...appContext.store.holidays.map(date => new Date(date.replace(/-/g, '/'))),
                {
                  before: availableDatesNormalized.minDate,
                },
                {
                  after: availableDatesNormalized.maxDate,
                },
                {
                  dayOfWeek: [0, 6],
                },
              ]}
              onDateSelect={date => {
                if (isRushCriticalDate(date)) {
                  setRushCriticalModalShown(true);
                  return false;
                }
                onDateChange(date);
                return true;
              }}
              dates={{
                freeShipping: availableDatesNormalized.freeShippingDates,
                critical: availableDatesNormalized.rushCriticalDates,
                standard: availableDatesNormalized.normalDates,
              }}
              customText={deliveryDate ? format(deliveryDate, 'MMM do') : null}
            />
          </Grid.Col>
          <Grid.Col className={cn('py-5', !!appContext.store.admin && 'pl-1 pr-0')} sm={12} md={6} lg={6}>
            {availableTimes ? (
              <Combobox
                value={{
                  key: selectedTime,
                  label: selectedTime === defaultDeliveryTime ? 'End of day' : selectedTime,
                }}
                onChange={option => simplyShipContext.onTimeChange(option.key)}
                className={!!appContext.store.admin && 'px-0 text-sm'}
                disabled={availableTimes.length <= 1}
                options={availableTimes.map(timeSlot => ({
                  key: timeSlot,
                  label: timeSlot === defaultDeliveryTime ? 'End of day' : timeSlot,
                }))}
                data-cy="deliveryDetailTimePicker"
              />
            ) : (
              <Loader dataCy="deliveryDetailsSpinner" />
            )}
          </Grid.Col>
        </Grid.Row>
      ) : (
        <Grid.Row
          className={cn(
            'border border-blue-200 pl-7 pr-7 pb-8 !m-0 md:mb-6 md:pb-0 items-center',
            !!checkoutPageMode && 'flex border-0 p-0 -mx-3',
          )}
        >
          <Loader className="my-2" dataCy="deliveryDetailsSpinner" />
        </Grid.Row>
      )}
    </Grid.Container>
  );
};
export default DeliveryDetail;
