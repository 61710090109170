import * as React from 'react';
import cn from 'classnames';
import css from './PurchaseSummary.scss';

interface ItemProps {
  noBorder?: boolean;
  title: React.ReactNode;
  value: React.ReactNode;
  bold?: boolean;
  className?: string;
  valueDataCy: string;
}

const ItemRow = ({ noBorder, title, value, bold, valueDataCy, ...props }: ItemProps) => (
  <div
    className={cn(
      'flex content-center justify-between',
      noBorder ? 'border-none' : 'border-b border-solid border-gray-50 pb-6',
      props.className,
    )}
  >
    <div
      className={cn(
        css.noMargin,
        'paragraph-medium-mobile text-gray-500',
        bold ? '!font-hvBold !font-bold text-default' : null,
      )}
    >
      {title}
    </div>
    <div
      className={cn(
        css.noMargin,
        'paragraph-mobile text-gray-500',
        bold ? '!font-hvBold !font-bold text-default' : null,
      )}
      data-cy={valueDataCy}
    >
      {value}
    </div>
  </div>
);

export default ItemRow;
