import * as React from 'react';
import find from 'lodash-es/find';
import { LineItem, Order, Upload } from 'api/orders/types';
import { removeFromCart } from 'api/gtm';
import QuoteEditModal from './QuoteEditModal/QuoteEditModal';
import PersephoneContext from 'contexts/PersephoneContextContainer/PersephoneContext';
import UploadFilesModal from './UploadFilesModal/UploadFilesModal';
import Header from './Header/Header';
import ProductLine from './ProductLine/ProductLine';
import { KeyVal } from 'libs/utils/common-types';
import { type OrderDispatchType } from 'app/contexts/OrderContext/actions';
import TempOrderTotalsContext from 'contexts/TempOrderTotalsContext/TempOrderTotalsContext';

interface Props {
  order: Order;
  isLoading: boolean;
  canEdit?: boolean;
  canUpload?: boolean;
  doRemoveLineItem?: (dispatch: OrderDispatchType, id: number) => Promise<void>;
  doUpdateLineItem?: (
    dispatch: OrderDispatchType,
    orderNumber: string,
    id: number,
    quoterJson: KeyVal,
  ) => Promise<void>;
  onRemoveUpload?: (dispatch: OrderDispatchType, id: number) => void;
  onAddUpload?: (dispatch: OrderDispatchType, id: number, upload: Upload) => void;
  checkoutMode?: boolean;
  dispatch?: OrderDispatchType;
}

const ProductsTable = ({
  order,
  isLoading,
  canEdit,
  canUpload,
  doRemoveLineItem,
  doUpdateLineItem,
  onRemoveUpload,
  onAddUpload,
  checkoutMode,
  dispatch,
}: Props) => {
  const [editingLineItem, setEditingLineItem] = React.useState<LineItem>(null);
  const [uploadingToLineItem, setUploadingToLineItem] = React.useState<LineItem>(null);
  const [deletingLineItem, setDeletingLineItem] = React.useState<LineItem>(null);
  const persephone = React.useContext(PersephoneContext);
  const tempOrderTotalsContext = React.useContext(TempOrderTotalsContext);
  const orderTotals = tempOrderTotalsContext?.orderTotals;

  const getLineItem = (id: number): LineItem => find(order.lineItems, i => i.id === id) as LineItem;

  const getLineItemTotal = (lineItem: LineItem): number => {
    if (orderTotals && orderTotals.lineItemTotals[lineItem.id.toString()]) {
      return parseFloat(orderTotals.lineItemTotals[lineItem.id.toString()]);
    }

    return parseFloat(lineItem.total);
  };

  const handleRemoveClick = (lineItemId: number) => {
    const lineItemToDelete = getLineItem(lineItemId);
    setDeletingLineItem(lineItemToDelete);
    doRemoveLineItem(dispatch, lineItemId).then(() => {
      removeFromCart({
        item_name: lineItemToDelete.product.name,
        item_id: lineItemToDelete.product.slug,
        price: parseFloat(lineItemToDelete.total) / lineItemToDelete.quantity,
        quantity: lineItemToDelete.quantity,
      }).then(() => {
        setDeletingLineItem(null);
      });
    });
  };

  return (
    <>
      {!!uploadingToLineItem && (
        <UploadFilesModal
          order={order}
          lineItem={uploadingToLineItem}
          owner={uploadingToLineItem.fileVersions[0]}
          ownerType="Mgx::FileVersion"
          onClose={() => setUploadingToLineItem(null)}
          onAddUpload={onAddUpload}
          onRemoveUpload={onRemoveUpload}
          dispatch={dispatch}
        />
      )}
      {!!editingLineItem && !!persephone.catalog && (
        <QuoteEditModal
          doUpdateLineItem={doUpdateLineItem}
          lineItemId={editingLineItem.id}
          alias={editingLineItem.alias}
          initialValues={editingLineItem.quoterJson}
          initialPrice={editingLineItem.total}
          onClose={() => setEditingLineItem(null)}
          catalog={persephone.catalog}
          productionDays={order.productionDays}
          dispatch={dispatch}
        />
      )}
      <Header
        dataCy="itemsTableHeader"
        hideUpload={!!uploadingToLineItem || !canUpload}
        noPaddingTop={!!checkoutMode}
      />
      {!!order &&
        order.lineItems.map(item => (
          <ProductLine
            disabled={deletingLineItem && deletingLineItem.id === item.id}
            key={item.id}
            order={order}
            lineItem={item}
            lineItemTotal={getLineItemTotal(item)}
            onEditClick={id => setEditingLineItem(getLineItem(id))}
            onRemoveClick={handleRemoveClick}
            onUploadClick={id => setUploadingToLineItem(getLineItem(id))}
            canEdit={canEdit}
            canUpload={canUpload}
            isLoading={isLoading}
            deliveryDate={orderTotals?.shipment?.deliveryDate || order.shipment?.deliveryDate}
          />
        ))}
    </>
  );
};

export default ProductsTable;
